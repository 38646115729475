//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD2tUdhRyFcFJh5mWqMy2h26XViT4Ap0fM",
  authDomain: "pirate-s-fortune.firebaseapp.com",
  projectId: "pirate-s-fortune",
  storageBucket: "pirate-s-fortune.firebasestorage.app",
  messagingSenderId: "775111969019",
  appId: "1:775111969019:web:c0926cacb5c948ad16085e",
  measurementId: "G-H56XJED34F",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
